import gql from 'graphql-tag';

export const TemplateInfoQuery = gql`
	query TemplateInfoQuery($templateId: ID!) {
		templateInfo(id: $templateId) {
			name
			author
			description
			iconURL
			darkModeIconURL
			styleClass
			templateId
			itemModuleCompleteKey
			blueprintModuleCompleteKey
			contentBlueprintId
			spaceKey
		}
	}
`;
