import { useCallback, useRef, useState } from 'react';

export const SHOW_DELAY = 400;
export const HIDE_DELAY = 200;

type HoverPoweredByPopupType = {
	isPopupOpen: boolean;
	startOpenPopupTimer: () => void;
	startClosePopupTimer: () => void;
};

export const useHoverPoweredByPopup = (): HoverPoweredByPopupType => {
	const [isPopupOpen, setPopupOpen] = useState(false);
	const openPopupTimerRef = useRef<NodeJS.Timeout | null>(null);
	const closePopupTimerRef = useRef<NodeJS.Timeout | null>(null);

	const openPopup = useCallback(() => {
		setPopupOpen(true);
	}, [setPopupOpen]);

	const closePopup = useCallback(() => {
		setPopupOpen(false);
	}, [setPopupOpen]);

	const _clearOpenPopupTimer = useCallback(() => {
		if (openPopupTimerRef?.current) {
			clearTimeout(openPopupTimerRef.current);
			openPopupTimerRef.current = null;
		}
	}, [openPopupTimerRef]);

	const _clearClosePopupTimer = useCallback(() => {
		if (closePopupTimerRef?.current) {
			clearTimeout(closePopupTimerRef.current);
			closePopupTimerRef.current = null;
		}
	}, [closePopupTimerRef]);

	const _clearAllTimers = useCallback(() => {
		_clearOpenPopupTimer();
		_clearClosePopupTimer();
	}, [_clearOpenPopupTimer, _clearClosePopupTimer]);

	const _setOpenPopupTimer = useCallback(() => {
		if (openPopupTimerRef?.current === null) {
			openPopupTimerRef.current = setTimeout(() => {
				openPopup();
				openPopupTimerRef.current = null;
			}, SHOW_DELAY);
		}
	}, [openPopup]);

	const _setClosePopupTimer = useCallback(() => {
		if (closePopupTimerRef?.current === null) {
			closePopupTimerRef.current = setTimeout(() => {
				closePopup();
				closePopupTimerRef.current = null;
			}, HIDE_DELAY);
		}
	}, [closePopup]);

	const startOpenPopupTimer = () => {
		_clearAllTimers();
		_setOpenPopupTimer();
	};

	const startClosePopupTimer = () => {
		_clearAllTimers();
		_setClosePopupTimer();
	};

	return {
		isPopupOpen,
		startOpenPopupTimer,
		startClosePopupTimer,
	};
};
